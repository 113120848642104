.rollover__wrapper {
  display: inline-block;
  z-index: 1;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.23);
  border: solid 1px #cccccc;
  overflow: hidden;
}

.rollover__details_wrapper-community {
  @extend .rollover__details_wrapper;
  min-height: 0px !important;
}

.rollover__details_wrapper-sale {
  @extend .rollover__details_wrapper;

  min-height: 0px !important;
  padding: 10px !important;
}

.rollover__details_wrapper {
  width: 284px;
  min-height: 96px;
  padding: 11px;
}

.rollover__community {
  color: $community-color;
  font-size: 14px;
  margin: 0;
  line-height: 18px;
  margin-bottom: 5px;
}

.rollover__dealer {
  @extend .rollover__community;
  color: $dealer-color;
}

.rollover__buy {
  @extend .rollover__community;
  color: $sale-color;
  font-weight: $mhbo-weight-bold;
}

.rollover__rent {
  @extend .rollover__community;
  color: $rent-color;
  font-weight: $mhbo-weight-bold;
  font-size: 1.1em;
}

.rollover__detail_lines {
  font-size: 10px;
  color: $lightGrey;
  margin: 0;
}

.rollover__detail_line1 {
  text-align: right;
  flex: 1;
}

.rollover__image {
  height: 171px;
  width: 284px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.rollover__image-community {
  @extend .rollover__image;

  height: 118px;
}

.rollover__image-sale {
  @extend .rollover__image;

  height: 118px;
}

.rollover__clustertotal {
  text-align: center;
  padding: 5px 0px;
}

.rollover__clustertotal {
  text-align: center;
  line-height: 1.3em;
  font-size: 1.1em;
}

.rollover__clusterpager {
  text-align: center;
  padding: 5px 5px 5px 0px;
}

.rollover__clusterpager__text {
  text-align: center;
  font-size: 12px;
}
