.searchFilters__close {
  display: inline-block;
  background: none;
  margin-right: 1rem;
  border: none;
  color: #999999;
  font-size: 0.8em;
  margin-top: 1.3rem;
}

.searchFilters__done {
  display: inline-block;
  padding: 0.5rem 3rem;
  border-radius: 5px;
  background-color: $highlight-color;
  border-color: $highlight-color;
  font-weight: $mhbo-weight-bold;
  transition: all 400ms cubic-bezier(0.55, 0.09, 0.68, 0.53);
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #ff9d3c;
  }
}

.searchFilters__cover {
  position: absolute;
  z-index: 2;
  top: 4.5rem;
  @media (max-width: 768px) {
    top: 0;
    left: 0;
  }
}

.searchFilter__container {
  height: 20rem;
  position: relative;
  z-index: 1;
  width: 32rem;
  @media (max-width: 768px) {
    width: auto;
    height: auto;
    position: initial;
  }
}

.searchFilter__box {
  background: #f6f6f6;
  padding: 25px 10px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0px 10px 25px 10px;
    border: none;
    background: inherit;
  }
}

.searchFilter__button {
  text-align: right;
  margin: 2rem 0 0 auto;
  @media (max-width: 768px) {
    margin: 0;
    align-content: end;
    flex: 1;
  }
}
