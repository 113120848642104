.boundary_toggle__wrapper {
  margin-top: 5px;
  background-color: white;
  padding: 0.5rem;
  cursor: pointer;
  opacity: 0.6;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
}
