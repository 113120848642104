.pagination__wrapper{
    justify-content: center;  
    margin: 1rem auto;
    .pagination {
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        font-size: 1.1em;
        border-color: transparent;
      }
      .page-link {
        transition: 0.3s;
        color: #f79622;
        border: none;
        &:hover{
            color: #ff8c00;
            border-color: transparent;
        }
    
      }
      .page-item.active .page-link {
        background-color: white;
        color: #1e2634;
        border-color: transparent;
      }
      .page-item.disabled {
        visibility: hidden;
      }
      
}

.pagination__text{
    color: #999999;
  font-size: 1.1em;
  text-align: center;
  margin-bottom: 0.5rem;
}

