// Colors
$orange: #ffaa56;
$white: #fff;
$navy: #12133d;
$gray: #ededed;
$darkGray: #7a7a7a;
$borderGrey: #979797;
$lightGrey: #777470;
$rent-color: #e2610a;
$sale-color: #22863b;
$community-color: #91278f;
$dealer-color: #b20404;
$selected-pin-color: #ffaa56;
$flag-color: #27a7d7;
$highlight-color: #f59433;

// Weights
$mhbo-weight-header: 300;
$mhbo-weight-paragraph: 400;
$mhbo-weight-bold: 500;

// Spacing
//$s-size: 1.2rem;
//$m-size: 1.6rem;
//$l-size: 3.2rem;
//$xl-size: 4.8rem;
