.legend__wrapper {
  background-color: rgba(255, 255, 255, 0.85);
  border: solid 1px $borderGrey;
  font-size: 0.8em;
  padding: 0.5rem;
  width: 13rem;
  @media (max-width: 768px) {
    width: 7rem;
  }
}

.legend__wrapper_closed {
  @extend .legend__wrapper;
  width: auto;
}

.legend__last__row {
  margin: 0;
}

.legend__row {
  @extend .legend__last__row;
  margin-bottom: 0.5rem;
}

.legend__marker_cell_text {
  margin-left: 0.1rem;
  line-height: 24px;
}

.legend__container {
  position: absolute;
  right: 3.6rem;
  top: 0.7rem;
  z-index: 1;
}
