
.sidebar__divider{
    margin-bottom: 1rem;  
}

.sidebar__subheading{
    font-size: 14px;
    color: #999;
    font-weight: 400;
    margin-top: 1.75rem;
    margin-bottom: 0.75rem;
    text-transform: uppercase;  
}

.sidebar__listingcount{
    color: #999999;
  font-size: 1.3em;
  margin-bottom: 0.1rem;
}



