.radioButtons__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 0.3rem;
  border: 1px solid #c0b7b7;
}

.radioButtons__button_col {
  position: relative;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.5rem;
  height: 100%;
  width: 25%;
  outline: none;
  flex: 1;
  cursor: pointer;
  background-color: $white;
  transition: all 300ms ease-in;
  &:not(:last-child) {
    border-right: 1px solid #c0b7b7;
  }
  font-size: inherit;
  text-align: center;
  color: #978e8e;
  &:hover {
    background-color: #f5f5f5;
  }
}

.radioButtons__button_3col {
  @extend .radioButtons__button_col;
  font-size: 1.1em;
}

.radioButtons__button_col_selected {
  @extend .radioButtons__button_col;
  background-color: $gray;
  color: black;
}

.radioButtons__button_3col_selected {
  @extend .radioButtons__button_3col;
  background-color: $gray;
}
.radioButtons__button_3col_buy {
  @extend .radioButtons__button_3col_selected;
  color: $sale-color;
}

.radioButtons__button_3col_rent {
  @extend .radioButtons__button_3col_selected;
  color: $rent-color;
}

.radioButtons__button_3col_community {
  @extend .radioButtons__button_3col_selected;
  color: $community-color;
}
