.dealerPreviewCell__chip {
  @extend .listingCell__chip;
  background-color: $dealer-color;
  display: inline-block;
  margin-left: 0;
  margin-top: 10px;
}

.dealerPreviewCell__name {
  flex-basis: 'auto';
  color: $dealer-color;
  font-size: 24px;
}

.dealerPreviewCell__more {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
  color: $lightGrey;
  font-size: 14px;
  &:hover {
    text-decoration: none;
    color: $lightGrey;
  }

  span {
    border-bottom: 1px solid $borderGrey;
    padding-bottom: 0.01rem;
  }
}

.dealerPreviewCell__contact {
  text-align: center;
  font-size: 14px;
  color: $lightGrey;
  line-height: 2.14;
  border-top: solid 1px $gray;
  border-bottom: solid 1px $gray;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  span {
    color: $orange;
    font-weight: $mhbo-weight-bold;
  }
}
