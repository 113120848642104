.share__wrapper {
  cursor: pointer;
  display: inline-flex;
  margin-left: 1rem;
  flex: 1;
  color: $orange;
  text-align: left;

  span {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: $mhbo-weight-bold;
    vertical-align: middle;
    margin-top: 4px;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-top: 12px;
    margin-right: 2px;
  }
}
