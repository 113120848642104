.Path-7 {
  width: 19.5px;
  height: 11.3px;
  margin: 12.6px 34.1px 0 34.1px;
  background-color: $navy;
}
.Rectangle-7 {
  width: max-content;
  margin: 0px;
  padding: 1.2px 5px 0.5px 5px;
  border-radius: 3px;
  color: white;
  border: 1px solid white;
}
.Lorem-Ipsum {
  width: 79px;
  height: 17px;
  font-family: 'Roboto';
  font-display: 'swap';
  font-size: 12px;
  font-weight: $mhbo-weight-bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: inherit;
}

.Rectangle-7-Community {
  @extend .Rectangle-7;
  background-color: $community-color;
}

.Rectangle-7-Sale {
  @extend .Rectangle-7;
  background-color: $sale-color;
}

.Rectangle-7-Rent {
  @extend .Rectangle-7;
  background-color: $rent-color;
}

.Rectangle-7-Dealer {
  @extend .Rectangle-7;
  background-color: $dealer-color;
}

.arrow-down {
  width: 0;
  margin: 0 auto;
  height: 0;
  top: 87%;
  position: absolute;
  z-index: 1;
  left: 50%;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.arrow-down-Community {
  @extend .arrow-down;
  left: 49%;
  border-top: 5px solid $community-color;
}
.arrow-down-Rent {
  @extend .arrow-down;
  border-top: 5px solid $rent-color;
}
.arrow-down-Sale {
  @extend .arrow-down;
  border-top: 5px solid $sale-color;
}
.arrow-down-Dealer {
  @extend .arrow-down;
  border-top: 5px solid $dealer-color;
}
.Group-7,
.Group-7-Hover {
  border-radius: 3px;
  position: absolute;
  transform: translate(-50%, -100%);
  display: inline-block;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}
.arrow-down-white {
  width: 0;
  margin: 0 auto;
  position: absolute;
  left: 49%;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
}

.Group-7:hover,
.Group-7-Hover {
  .Rectangle-7-Community {
    color: $community-color;
    background-color: white;
    border: 1px solid $community-color;
  }
  .Rectangle-7-Dealer {
    color: $dealer-color;
    background-color: white;
    border: 1px solid $dealer-color;
  }
  .arrow-down-Community,
  .arrow-down-Dealer,
  .arrow-down-Sale,
  .arrow-down-Rent {
    @extend .arrow-down;
    z-index: 0;
    top: 100%;
    position: absolute;
    left: 50%;
    border-left-width: 5px;
    border-right-width: 5px;
    border-top-width: 5px;
  }
  .arrow-down-white {
    top: 87%;
    position: absolute;
    z-index: 1;
    left: 50%;
    border-left-width: 5px;
    border-right-width: 5px;
    border-top-width: 5px;
  }
  .Rectangle-7-Sale {
    color: $sale-color;
    background-color: white;
    border: 1px solid $sale-color;
  }
  .Rectangle-7-Rent {
    color: $rent-color;
    background-color: white;
    border: 1px solid $rent-color;
  }
}
