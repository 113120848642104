.listingCell__chip {
  margin: 0.2rem;
  font-weight: $mhbo-weight-bold;
  font-size: 14px;
  border-radius: 5px;
  align-self: flex-start;
  flex-basis: auto;
  border: solid 1px #e6e6e6;
  width: auto;
  letter-spacing: 0.2px;
  line-height: 22px;
  color: white;
  clear: left;
  float: left;
  padding: 0.15rem 0.5rem;
  text-transform: uppercase;
}

.listingCell__chip_home {
  @extend .listingCell__chip;
  background-color: $orange;
}

.listingCell__chip_rent {
  @extend .listingCell__chip;
  background-color: $rent-color;
}

.listingCell__chip_sale {
  @extend .listingCell__chip;
  background-color: $sale-color;
}

.listingCell__chip_community {
  @extend .listingCell__chip;
  background-color: $community-color;
}

.listingCell__chip_flags {
  @extend .listingCell__chip;
  background-color: $flag-color;
  border-radius: 5px;
  border: solid 1px #e6e6e6;
  letter-spacing: 0.2px;
  line-height: 22px;
  margin: 0.2rem;
  font-size: 14px;
  padding: 0.15rem 0.5rem;
  text-transform: uppercase;
  svg {
    font-size: 20px;
    vertical-align: text-top;
  }
  img {
    width: 16px;
    height: 16px;
  }
  span {
    vertical-align: middle;
  }
}

.listingCell__footer_content {
  font-size: 12px;
  color: $white;
  text-align: left;
  font-weight: $mhbo-weight-header;
  margin: 0;
  svg {
    font-size: 20px;
    vertical-align: text-top;
  }
}

.listingCell__footer_content_navy {
  @extend .listingCell__footer_content;
  color: $navy;
}

.listingCell__footer_content_navy_right {
  @extend .listingCell__footer_content_navy;
  text-align: right;
}

.listingCell__footer_content_large_text {
  @extend .listingCell__footer_content;
  font-size: 32px;
}

.listingCell__footer_content_large_text_navy {
  @extend .listingCell__footer_content_large_text;
  color: $navy;
}

.listingCell__footer_content_large_text_navy_padded {
  @extend .listingCell__footer_content_large_text_navy;
  padding-left: 1rem;
  min-height: 4rem;
  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.listingCell__footer_content_align_right {
  @extend .listingCell__footer_content;
  text-align: right;
}

.listingCell__footer_content_right_large {
  @extend .listingCell__footer_content_large_text;
  text-align: right;
}

.listingCell__community_link {
  text-align: right;
  font-size: 18px;
  font-weight: $mhbo-weight-bold;
  margin: 0;
  color: orange;
  padding-top: 10px;
  cursor: pointer;
}

.listingCell__price_heading {
  color: $navy;
  text-align: left;
  font-size: 12px;
  font-weight: $mhbo-weight-bold;
  margin: 0;
  text-transform: uppercase;
}

.listingCell__price_number {
  color: $navy;
  text-align: left;
  font-size: 32px;
  margin: 0;
}

.listingCell__price_container {
  padding-left: 1rem;
}

.listingCell__footer_content_heavy_color_right_large {
  @extend .listingCell__footer_content_large_text;
  text-align: right;
  color: orange;
  font-size: 18px;
  font-weight: $mhbo-weight-bold;
}

.listingCell__preview_cell {
  width: 100%;
  margin: 0 0 1rem;
  position: relative;
  cursor: pointer;
  border: 1px solid #616770;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  &:hover {
    border: 1px solid $flag-color;
  }
}

.listingCell__preview_cell_header_common {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  min-height: 50px;
}

.listingCell__preview_cell_header {
  @extend .listingCell__preview_cell_header_common;
  position: absolute;
  top: 10px;
}

.listingCell__preview_cell_icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex-basis: 50%;
  width: 50%;
  margin-left: auto;

  svg {
    font-size: 54px;
  }

  svg path:not([fill='none']) {
    fill: rgba(18, 19, 61, 0.6);
    stroke: $white;
    stroke-width: 1.5;
  }
}

.listingCell__preview_cell_icons_favorite {
  @extend .listingCell__preview_cell_icons;
  svg path:not([fill='none']) {
    fill: $orange;
    stroke: $white;
    stroke-width: 1.5;
  }
}

.listingCell__preview_cell_footer {
  background-color: rgba(0, 0, 0, 0.6);
  min-height: 90px;
  padding: 5px;
  padding-top: 10px;
  width: 100%;
  bottom: 0px;
  color: $white;
}

.listingCell__preview_cell_footer_white {
  @extend .listingCell__preview_cell_footer;
  background-color: white;
  padding-right: 1rem;
}

.listingCell__preview_cell_image {
  height: 260px;
  width: 100%;
  object-fit: cover;
}

.listingCell__footer_content_bold {
  font-weight: 500;
}
