.propertyDropdown__wrapper_inner {
  display: inline-flex;
  flex-flow: column wrap;
  margin-left: 1rem;
  margin-top: 0.6rem;
  @media only screen and (max-device-width: 767px) {
    margin: 0.5rem 0.5rem 0 0;
  }
}

.propertyDropdown__menuItem {
  color: $lightGrey !important;
  font-size: 18px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
