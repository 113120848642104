.filterButton__wrapper {
  width: 100%;
  height: 40px;
  padding-left: 0;
  position: relative;
  margin-bottom: 0.5rem;
}

.filterButton__label {
  position: absolute;
  z-index: 2;
  line-height: 37px;
  left: 0;
  pointer-events: none;
  text-transform: capitalize;
  color: $borderGrey;
  padding-left: 8px;
  font-size: 17px;
  font-weight: $mhbo-weight-paragraph;
  svg {
    color: $orange;
    font-size: 25px;
  }
}

.filterButton__label_checked {
  @extend .filterButton__label;
  color: $white;
  svg {
    color: $white;
  }
}

.filterButton__button[type='checkbox'] {
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: $white;
  border: 1px solid $borderGrey;
  border-radius: 35px;
  outline: none;
  cursor: pointer;
  margin-left: 0;
  transition: all 300ms ease-in;
  margin-top: 0rem;
  &:checked {
    background-color: $orange;
    border: 1px solid $orange;
    background-image: none;
  }
}
