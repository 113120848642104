.mainSearchField {
  flex: 1 0 80%;
  width: 100%;
  padding: 0.3rem 0.7rem;
  font-size: 16px;
  background-color: #fefefe;
  border: 1px solid #cccccc;
  color: $lightGrey;
  ::-webkit-search-cancel-button {
    width: 12px;
    height: 12px;
    border: solid 1px #979797;
    background-color: #979797;
  }
}

.mainSearchField__suggestion_item {
  line-height: 1.27;
  letter-spacing: normal;
  color: $lightGrey;
  background-color: $white;
  margin: 1rem 2rem;
  font-size: 15px;
  cursor: pointer;
}

.mainSearchField__suggestion_item_active {
  @extend .mainSearchField__suggestion_item;
  background-color: #fafafa;
  cursor: pointer;
}

.mainSearchField__searchButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 20%;
  width: 20%;
  max-width: 80px;
  text-align: center;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.1);
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  svg {
    font-size: 16px;
    fill: rgba(0, 0, 0, 0.4);
  }

  &:hover {
    border: solid 1px #ccc;
  }
}

.mainSearchField__autocompleteContainer {
  position: fixed;
  background-color: white;
  z-index: 2;
}
