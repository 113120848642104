.modalSearch__header {
  color: $lightGrey;
  font-size: 36px;
  margin-bottom: 1rem ;
  text-align: center;
  font-weight: $mhbo-weight-header;
}

.modalSearch__close {
  float: right;
  color: $lightGrey;
  cursor: pointer;
  svg {
    width: 27px;
    height: 27px;
  }
}

.modalSearch__text {
  color: $lightGrey;
  font-size: 16px;
  text-align: center;
}

.modalSearch__url {
  border-radius: 3px;
  border: solid 1px $borderGrey;
  color: #8a8884;
  white-space: nowrap;
  padding: 1rem;
  display: flex;
}

.modalSearch__copy {
  font-size: 16px;
  font-weight: $mhbo-weight-bold;
  text-align: right;
  margin-left: 1rem;
  color: #3390f5;
  text-transform: uppercase;
  cursor: pointer;
}

.modalSearch__url_scroll {
  overflow-x: scroll;
  font-size: 14px;
  flex-grow: 1;
}
