.header__navigation {
  background-color: #12133d;
  padding-top: 0;
  padding-bottom: 0;
  color: $white;
  flex-grow: 0;
  flex-shrink: 0;
}

.header__brand {
  font-size: 3.25rem;
  font-weight: $mhbo-weight-bold;
}

.header__white {
  color: #fff !important;
  font-weight: $mhbo-weight-paragraph;
}

.header__orange {
  color: $orange !important;
}

.header__spacer {
  line-height: 40px;
}
