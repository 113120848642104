.circularMarker__Container {
  position: absolute;
  transform: translate(-50%, -50%);
  display: inline-block;
  .circularMarker__Cover {
    visibility: hidden;
  }
}
.circularMarker__Container:hover {
  .circularMarker__Cover {
    visibility: visible;
  }
}
.circularMarker__ContainerHover {
  @extend .circularMarker__Container;
  .circularMarker__Cover {
    visibility: visible;
  }
}

.numberCircle {
  position: absolute;
  transform: translate(-50%, -50%);
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  border: 2px solid;
  font-size: 14px;
  background-color: #e2f3ff;
}

.numberCircle span {
  display: inline-block;
  color: black;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 8px;
  margin-right: 8px;
}
