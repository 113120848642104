.filterPanel__searchWrapper {
  clear: both;
  width: 24rem;
  max-width: 90vw;
  display: flex;
  float: left;
}

.filterPanel__wrapper {
  padding: 0.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
}
.filterPanel__bootstrapInput {
  border: solid 1px $borderGrey;
  border-radius: 3px;
  color: $lightGrey !important;
  font-size: 14px !important;
  padding-left: 5px;
  position: relative !important;
  &:focus {
    background-color: none;
    border: solid 1px $borderGrey;
    border-radius: 3px;
  }
}

.filterPanel__formControl {
  min-width: 100px !important;
}
.filterPanel__iconStyle {
  color: $borderGrey;
  display: inline-block !important;
  height: 24px !important;
  pointer-events: none;
  position: absolute;
  right: 10px;
  width: 24px !important;
}
