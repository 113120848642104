.modalEmail__signup {
    height: 4rem;
    margin-left: 1.5rem;
    width: 95%;
    font-size: 1.5em;
  }

.modalEmail__header{
    color: $lightGrey;
    font-size: 2.5em;
    margin: 2rem 4rem;
    text-align: center;
    font-weight: 300;
}

.modalEmail__text{
    color: #1e2634;
    font-size: 1.5em;
    margin: 1.5rem;
}

.modalEmail__button{
    border-radius: 5px;
    background-color: $highlight-color;
    height: 4rem;
    margin: 1rem auto;
    width: 95%;
    margin-left: 1.5rem;
    color: white;
    font-size: 1.8em;
    text-transform: uppercase;
}


  
