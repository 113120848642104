.breadcrumb__wrapper {
  margin-top: 1.5rem;
  margin-bottom: 0.7rem;
  font-size: 0.9em;
}

.breadcrumb__upperlevels {
  color: $highlight-color;
  cursor: pointer;
}

.breadcrumb__upperlevelsButton {
  color: $highlight-color;
  cursor: pointer;
  background-color: none;
  display: inline-block;
}
