.container__appShell {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container__mainFieldWrapper {
  display: inline-flex;
  flex-flow: row nowrap;
  margin-top: 0.5rem;
  font-size: 16px;
  width: 24rem;
}

.container__searchFieldWrapper {
  flex: 1;
  position: relative;
}

.container__mapAndSidebar {
  display: flex;
  width: 100%;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: row;
}

.container__sidebarcolumn {
  width: 41.66%;
  max-width: 450px;
  padding: 0 1rem;
  overflow-y: scroll;
}

.container__mapcolumn {
  display: flex;
  flex-grow: 1;
}

.container_mapcolumn_mobile {
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.container__mapWrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.container__toggleButton {
  @media only screen and (max-device-width: 767px) {
    display: flex;
    position: fixed;
    width: 100%;
    bottom: 15px;
    justify-content: center;
  }
  display: none;
}

.gptads__cover {
  &:first-child {
    padding: 10px 0 10px 0;
  }
}
