@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import './base/settings';
@import './base/base';
@import './components/header';
@import './components/legend';
@import './components/dealerPreviewCell';
@import './components/mainSearchField';
@import './components/filterButton';
@import './components/searchForm';
@import './components/modalShare';
@import './components/radioButtons';
@import './components/rollover';
@import './components/propertyDropdown';
@import './components/searchFilters';
@import './components/boundaryToggle';
@import './components/circularMarker';
@import './components/communityMarker';
@import './components/breadcrumb';
@import './components/modalEmail';
@import './components/pagination';
@import './components/share';

// Removes maps in mobile viewports

#sidebar {
  @media only screen and (max-device-width: 767px) {
    width: 100vw;
  }
}

#location-search {
  @media only screen and (max-device-width: 767px) {
    max-width: 94vw;
  }
}
