.searchForm__button_submit {
  display: inline-block;
  padding: 0.5rem 3rem;
  margin: 0.5rem 0 0.1rem 0;
  border: 1px solid $darkGray;
  border-radius: 6px;
  background-color: $gray;
  text-transform: capitalize;
  font-size: 1em;
  font-weight: $mhbo-weight-bold;
  cursor: pointer;
  color: $darkGray;
  :focus {
    outline: none;
  }
  & + & {
    margin-left: 0.4rem;
  }
}
